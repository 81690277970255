import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { renderStyledElementStyles } from '../../../../helpers';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  text-align: center;
  transform: rotate(-45deg) translate(0, -70%);
  padding: 55px 25px 5px;

  ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}
  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)};
`;

export const CornerLabel = ({ content, dStyles, vStyles, style }) => {
  return (
    <Wrapper
      dStyles={dStyles.elements?.card.elements?.cornerLabel}
      vStyles={vStyles?.elements?.card.elements?.cornerLabel}
      style={style}
    >
      {content}
    </Wrapper>
  );
};

CornerLabel.propTypes = {
  content: PropTypes.string,
};
