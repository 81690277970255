import React from 'react';
import PropTypes from 'prop-types';

import { splitListIntoChunks } from '../../../../helpers';

import { DropColumnSubSectionsTypes } from '../../types';

import {
  CallToActionConfigProps,
  StyledCallToAction,
  StyledHeading,
  StyledParagraph,
} from '../../../sharedComponents';
import { AlignWrapper } from '../../index.style';
import { Row } from '../../innerComponents';

export const DefaultDisplayStyle = (props) => {
  const {
    section: {
      title: heading,
      titleSize,
      subtitle: paragraph,
      subsections = [],
      link: ctaConfig = {},
      textAlignment: headingTextAlignment,
      columnLayoutAlignment,
      blocksTextAlignment,
      openDropColumnsInRow,
    },
    count,
    dStyles,
    vStyles,
  } = props;

  const {
    elements: {
      callToAction: { settings: { mode: callToActionMode } = {} } = {},
    } = {},
  } = vStyles;
  const {
    settings: {
      columnHeading: { md },
    },
  } = dStyles;

  return (
    <div className="container">
      <div className="row">
        <div className={`col-xs-12 col-md-${md}`}>
          {heading && (
            <AlignWrapper
              elementName="heading"
              alignStyle={headingTextAlignment}
              dStyles={dStyles}
              vStyles={vStyles}
            >
              <StyledHeading
                content={heading}
                as={titleSize}
                displayStyles={dStyles}
                visualStyles={vStyles}
              />
            </AlignWrapper>
          )}
          {paragraph && (
            <AlignWrapper
              elementName="paragraph"
              alignStyle={headingTextAlignment}
              dStyles={dStyles}
              vStyles={vStyles}
            >
              <StyledParagraph
                content={paragraph}
                displayStyles={dStyles}
                visualStyles={vStyles}
                alignStyle={headingTextAlignment}
              />
            </AlignWrapper>
          )}
        </div>
      </div>

      {splitListIntoChunks(subsections, count).map((list, index) => (
        <Row
          list={list}
          count={count}
          alignStyle={blocksTextAlignment}
          alignColumns={columnLayoutAlignment}
          dStyles={dStyles}
          vStyles={vStyles}
          rowIsOpened={openDropColumnsInRow && index === 0}
          key={index}
        />
      ))}

      {ctaConfig.shown && (
        <div className="row">
          <div className="col-xs-12">
            <StyledCallToAction
              ctaConfig={ctaConfig}
              mode={callToActionMode}
              displayStyles={dStyles}
              visualStyles={vStyles}
            />
          </div>
        </div>
      )}
    </div>
  );
};

DefaultDisplayStyle.propTypes = {
  count: PropTypes.oneOf([1, 2, 3, 4, 6, 12]).isRequired,
  section: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    link: CallToActionConfigProps,
    textAlignment: PropTypes.oneOf(['LEFT', 'CENTRE']).isRequired,
    openDropColumnsInRow: PropTypes.bool,
    subsections: PropTypes.arrayOf(PropTypes.shape(DropColumnSubSectionsTypes)),
  }).isRequired,
};
