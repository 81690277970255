import React from 'react';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';
import {
  ImageTagConfigProps,
  StyledImageTag,
  StyledImageTagWrapper,
} from '../../../sharedComponents';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}
  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${StyledImageTagWrapper} {
    height: 100%;
  }
`;

export const Image = (props) => {
  const { imageConfig, alt, dStyles, vStyles, onClick, style } = props;

  return (
    <Wrapper
      onClick={onClick}
      style={style}
      dStyles={dStyles}
      vStyles={vStyles}
    >
      <div className="embed-responsive embed-responsive-16by9">
        <div className="embed-responsive-item">
          <StyledImageTag imageConfig={imageConfig} alt={alt} />
        </div>
      </div>
    </Wrapper>
  );
};

Image.propTypes = {
  imageConfig: ImageTagConfigProps,
  alt: PropTypes.string,
};
