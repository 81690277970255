import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { renderStyledElementStyles } from '../../../../helpers';

const Wrapper = styled.h3`
  transition: font-size 0.4s;
  ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}
  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)}
  
  &.revealed {
    ${({ theme, vStyles }) =>
      renderStyledElementStyles(theme, vStyles.revealed)}
  }
`;

export const Title = ({ className, content, style, dStyles, vStyles, as }) => (
  <Wrapper
    className={className}
    dStyles={dStyles.elements?.card.elements?.title}
    vStyles={vStyles.elements?.card.elements?.title}
    style={style}
    as={as.toLowerCase()}
  >
    {content}
  </Wrapper>
);

Title.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  as: PropTypes.string,
};
