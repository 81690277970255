import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';

import { renderStyledElementStyles } from '../../helpers';
import { SiteBuilderModule, StyledSVGEmbed } from '../sharedComponents';
import { DefaultDisplayStyle } from './displayStyles';

const Wrapper = styled.div`
  position: relative;
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.siteBuilderModules.dropColumnModule)}
  ${({ theme, displayStyle }) =>
    renderStyledElementStyles(
      theme,
      theme.siteBuilderModules.dropColumnModule.displayStyles[displayStyle],
    )}
  ${({ theme, visualStyle }) =>
    renderStyledElementStyles(
      theme,
      theme.siteBuilderModules.dropColumnModule.visualStyles[visualStyle],
    )}
`;
export const DropColumnModule = (props) => {
  const {
    section: { style: visualStyle, columnStyle: displayStyle },
  } = props;

  const themeConfig = useContext(ThemeContext);

  const vStyles =
    themeConfig.siteBuilderModules.dropColumnModule.visualStyles[visualStyle];
  const dStyles =
    themeConfig.siteBuilderModules.dropColumnModule.displayStyles[displayStyle];

  const { settings: { backgroundSVGConfig = {} } = {} } = vStyles;
  const countConfig = dStyles.settings.columnCount;

  return (
    <SiteBuilderModule section={props.section}>
      <Wrapper displayStyle={displayStyle} visualStyle={visualStyle}>
        {backgroundSVGConfig.path && (
          <StyledSVGEmbed
            href={backgroundSVGConfig.path}
            colors={backgroundSVGConfig}
          />
        )}
        <DefaultDisplayStyle
          count={countConfig}
          section={props.section}
          dStyles={dStyles}
          vStyles={vStyles}
        />
      </Wrapper>
    </SiteBuilderModule>
  );
};

DropColumnModule.propTypes = {
  section: PropTypes.shape({
    style: PropTypes.oneOf([
      'LIGHT',
      'DARK',
      'STANDARD_DARK',
      'STANDARD_LIGHT',
      'BRAND_LIGHT',
      'BRAND_DARK',
      'LARGE_DARK',
      'LARGE_LIGHT',
    ]).isRequired,
    columnStyle: PropTypes.oneOf([
      'TWO_COLUMNS',
      'THREE_COLUMNS',
      'FOUR_COLUMNS',
    ]).isRequired,
  }).isRequired,
};

export default DropColumnModule;
