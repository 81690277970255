import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const HeaderBlock = styled.div`
  position: relative;
  overflow: hidden;
  ${({ theme, dStyles }) =>
    renderStyledElementStyles(
      theme,
      dStyles.elements?.card.elements?.headerBlock,
    )}
  ${({ theme, vStyles }) =>
    renderStyledElementStyles(
      theme,
      vStyles.elements?.card.elements?.headerBlock,
    )}
`;
