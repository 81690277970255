import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { renderStyledElementStyles } from '../../../../helpers';

import { Image } from '../Image';
import {
  CallToActionConfigProps,
  ImageTagConfigProps,
  Paragraph,
} from '../../../sharedComponents';

const Wrapper = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s;
  ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}
  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)};

  &.revealed {
    max-height: 500rem;
  }
`;

const TextWrapper = styled.div`
  ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}
  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)};
  ${({ theme, vStyles, alignStyle }) =>
    renderStyledElementStyles(theme, vStyles.alignStyles[alignStyle])}
`;

export const RevealPanel = ({
  content,
  className,
  revealedImage = {},
  alt,
  dStyles,
  vStyles,
  style,
  alignStyle,
}) => {
  return (
    <Wrapper
      className={className}
      style={style}
      dStyles={dStyles.elements?.card.elements?.revealPanel}
      vStyles={vStyles.elements?.card.elements?.revealPanel}
    >
      {content && (
        <TextWrapper
          dStyles={dStyles.elements?.card.elements?.textWrapper}
          vStyles={vStyles.elements?.card.elements?.textWrapper}
          alignStyle={alignStyle}
        >
          <Paragraph content={content} alignStyle={alignStyle} />
        </TextWrapper>
      )}
      {revealedImage.path && (
        <Image
          imageConfig={revealedImage}
          alt={alt}
          dStyles={dStyles.elements?.card.elements?.revealImage}
          vStyles={vStyles.elements?.card.elements?.revealImage}
        />
      )}
    </Wrapper>
  );
};

RevealPanel.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  revealedImage: ImageTagConfigProps,
  ctaConfig: CallToActionConfigProps,
  showDisableCTAButton: PropTypes.bool,
  callToActionButtonText: PropTypes.string,
};
