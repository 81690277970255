import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { renderStyledElementStyles } from '../../../../helpers';

const Wrapper = styled.div`
  transition: font-size 0.4s;
  ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}
  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)}

  &.revealed {
    ${({ theme, vStyles }) =>
      renderStyledElementStyles(theme, vStyles.revealed)}
  }
`;

export const SubTitle = ({ className, content, style, dStyles, vStyles }) => {
  return (
    <Wrapper
      className={className}
      dStyles={dStyles.elements?.card.elements?.subTitle}
      vStyles={vStyles.elements?.card.elements?.subTitle}
      style={style}
    >
      {content}
    </Wrapper>
  );
};

SubTitle.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
};
