import PropTypes from 'prop-types';
import {
  CallToActionConfigProps,
  ImageTagConfigProps,
} from '../sharedComponents';

export const DropColumnSubSectionsTypes = {
  headerBlockColour: PropTypes.string,
  headerTextColour: PropTypes.string,
  title: PropTypes.string,
  titleSize: PropTypes.string,
  subTitle: PropTypes.string,
  subHeadColour: PropTypes.string,
  strapline: PropTypes.string,
  straplineBackgroundColour: PropTypes.string,
  straplineColour: PropTypes.string,
  cornerLabelText: PropTypes.string,
  displayCornerLabel: PropTypes.bool,
  headerImage: ImageTagConfigProps,
  headerAlt: PropTypes.string,
  revealedPanelBackgroundColour: PropTypes.string,
  alt: PropTypes.string,
  body: PropTypes.string,
  revealedImage: ImageTagConfigProps,
  link: CallToActionConfigProps,
  showDisableCTAButton: PropTypes.bool,
  callToActionButtonText: PropTypes.string,
};
