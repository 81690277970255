import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { renderStyledElementStyles } from '../../../../helpers';
import {
  CallToAction,
  CallToActionConfigProps,
} from '../../../sharedComponents';

const Wrapper = styled.div`
  text-align: center;

  a {
    display: inline-block;
    ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}
    ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)}
    
    &.disabled {
      cursor: initial;
      pointer-events: none;
      opacity: 1;
      background: #e6e6e6;
      color: #fff;
      border-color: transparent;
      box-shadow: none;
    }
  }
`;

export const Link = (props) => {
  const { text, ctaConfig, disabled, dStyles, vStyles, style } = props;
  return (
    <Wrapper
      dStyles={dStyles.elements?.card?.elements?.link}
      vStyles={vStyles.elements?.card?.elements?.link}
    >
      <CallToAction
        className={classNames({ disabled })}
        type="button"
        ctaConfig={ctaConfig}
        style={style}
      >
        {disabled && text}
      </CallToAction>
    </Wrapper>
  );
};

Link.propTypes = {
  text: PropTypes.string,
  ctaConfig: CallToActionConfigProps,
  disabled: PropTypes.bool,
};
