import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { DropColumnSubSectionsTypes } from '../../types';
import { Card } from '../Card';
import { renderStyledElementStyles } from '../../../../helpers';
import cn from 'classnames';

const Wrapper = styled.div`
  ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}
  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)}
`;

export const Row = (props) => {
  const {
    rowIsOpened,
    list,
    count,
    alignStyle,
    alignColumns,
    dStyles,
    vStyles,
  } = props;

  return (
    <Wrapper
      className={cn('row', {
        'center-sm': alignColumns === 'CENTRE',
        'end-sm': alignColumns === 'RIGHT',
      })}
      dStyles={dStyles.elements?.row}
      vStyles={vStyles.elements?.row}
    >
      {list.map((column, index) => (
        <div key={index} className={`col-xs-12 col-sm-${12 / count}`}>
          <Card
            column={column}
            columnIsRevealed={rowIsOpened}
            dStyles={dStyles}
            vStyles={vStyles}
            alignStyle={alignStyle}
          />
        </div>
      ))}
    </Wrapper>
  );
};

Row.propTypes = {
  rowIsOpened: PropTypes.bool,
  count: PropTypes.oneOf([1, 2, 3, 4, 6, 12]).isRequired,
  list: PropTypes.arrayOf(PropTypes.shape(DropColumnSubSectionsTypes))
    .isRequired,
};
