import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { renderStyledElementStyles } from '../../../../helpers';

import { DropColumnSubSectionsTypes } from '../../types';

import { Image } from '../Image';
import { HeaderBlock } from '../HeaderBlock';
import { CornerLabel } from '../CornerLabel';
import { Title } from '../Title';
import { SubTitle } from '../SubTitle';
import { StrapLine } from '../StrapLine';
import { RevealPanel } from '../RevealPanel';
import { RevealButton } from '../RevealButton';
import { Link } from '../Link';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:last-child {
    margin-bottom: 0;
  }

  ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}
  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)}
`;
export const Card = ({
  column,
  columnIsRevealed,
  dStyles,
  vStyles,
  alignStyle,
}) => {
  const {
    headerBlockColour,
    headerTextColour,
    title,
    titleSize = 'H2',
    subTitle,
    subTitleColour,
    strapline,
    straplineBackgroundColour,
    straplineColour,
    cornerLabelText,
    cornerLabelTextColour,
    cornerLabelBackgroundColour,
    displayCornerLabel,
    headerImage = {},
    headerAlt,
    revealedPanelBackgroundColour,
    alt,
    body,
    revealedImage = {},
    link: ctaConfig = {},
    showDisableCTAButton,
    callToActionButtonText,
    buttonTextColour,
    buttonBackgroundColour,
    buttonDisabledTextColour,
    buttonDisabledBackgroundColour,
    arrowColour,
  } = column;
  const [isRevealed, setIsRevealed] = useState(columnIsRevealed);
  const revealedClassName = classNames({ revealed: isRevealed });
  const isRevealable = !!body || ctaConfig.shown || revealedImage.path;

  const revealButtonClickHandler = () => {
    if (isRevealable) setIsRevealed(!isRevealed);
  };

  return (
    <Wrapper dStyles={dStyles.elements?.card} vStyles={vStyles.elements?.card}>
      {headerImage.path && (
        <Image
          imageConfig={headerImage}
          alt={headerAlt}
          onClick={revealButtonClickHandler}
          style={{ cursor: isRevealable && 'pointer' }}
          dStyles={dStyles.elements?.card.elements?.headerImage}
          vStyles={vStyles.elements?.card.elements?.headerImage}
        />
      )}
      <HeaderBlock
        style={{
          backgroundColor: headerBlockColour,
          cursor: isRevealable && 'pointer',
        }}
        dStyles={dStyles}
        vStyles={vStyles}
        alignStyle={alignStyle}
        onClick={revealButtonClickHandler}
        revealable={isRevealable}
      >
        {isRevealable && (
          <RevealButton
            isActive={isRevealed}
            dStyles={dStyles}
            vStyles={vStyles}
            style={{ color: arrowColour }}
          />
        )}
        {displayCornerLabel && (
          <CornerLabel
            content={cornerLabelText}
            dStyles={dStyles}
            vStyles={vStyles}
            style={{
              color: cornerLabelTextColour,
              backgroundColor: cornerLabelBackgroundColour,
            }}
          />
        )}
        <Title
          content={title}
          className={revealedClassName}
          as={titleSize?.toLowerCase()}
          dStyles={dStyles}
          vStyles={vStyles}
          style={{ color: headerTextColour }}
        />
        {subTitle && (
          <SubTitle
            content={subTitle}
            className={revealedClassName}
            dStyles={dStyles}
            vStyles={vStyles}
            style={{ color: subTitleColour }}
          />
        )}
        <StrapLine
          content={strapline}
          className={revealedClassName}
          vStyles={vStyles}
          dStyles={dStyles}
          style={{
            backgroundColor: straplineBackgroundColour,
            color: straplineColour,
          }}
        />
      </HeaderBlock>
      {ctaConfig.shown && (
        <Link
          ctaConfig={ctaConfig}
          disabled={showDisableCTAButton}
          text={callToActionButtonText}
          dStyles={dStyles}
          vStyles={vStyles}
          alignStyle={alignStyle}
          style={
            showDisableCTAButton
              ? {
                  color: buttonDisabledTextColour,
                  background: buttonDisabledBackgroundColour,
                }
              : { color: buttonTextColour, background: buttonBackgroundColour }
          }
        />
      )}
      <RevealPanel
        className={revealedClassName}
        ctaConfig={ctaConfig}
        content={body}
        revealedImage={revealedImage}
        alt={alt}
        showDisableCTAButton={showDisableCTAButton}
        callToActionButtonText={callToActionButtonText}
        style={{ backgroundColor: revealedPanelBackgroundColour }}
        alignStyle={alignStyle}
        vStyles={vStyles}
        dStyles={dStyles}
      />
    </Wrapper>
  );
};

Card.propTypes = {
  columnIsRevealed: PropTypes.bool,
  column: PropTypes.shape(DropColumnSubSectionsTypes).isRequired,
};
