import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { renderStyledElementStyles } from '../../../../helpers';

const Wrapper = styled.div`
  position: absolute;
  font-size: 0;
  cursor: pointer;
  ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}
  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)}
  
  i {
    font-size: 32px;
  }
`;

export const RevealButton = (props) => {
  const { isActive, onClick, dStyles, vStyles, style } = props;
  return (
    <Wrapper
      dStyles={dStyles?.elements?.card?.elements?.revealButton}
      vStyles={vStyles?.elements?.card?.elements?.revealButton}
      onClick={onClick}
      style={style}
      data-testid="drop-column-card-expand-button"
    >
      <i className="material-icons accordion-expand-icon">
        {isActive ? 'expand_less' : 'expand_more'}
      </i>
    </Wrapper>
  );
};

RevealButton.propTypes = {
  onClick: PropTypes.func,
};
